.footer-container {
    position: relative;

}

.footer-container>hr {
    border: 1px solid var(--lightgray);

}

.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    justify-content: center;
    align-items: center;
    height: 20rem;
}

.social {
    display: flex;
    gap: 3rem;
}

.social>img {
    width: 3rem;
    height: 3rem;
    cursor: pointer;
}

.logo-f>img {
    margin-top: -5rem;
    width: 10rem;
}

.footblur1 {
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
}


.footblur2 {
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(255, 115, 0);
}