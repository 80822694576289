.header {
    display: flex;
    justify-content: space-between;
}

.header img {
    width: 10rem;
    height: 3rem;
}

.header-menu {
    text-decoration: none;
    list-style: none;
    display: flex;
    gap: 3rem;
    color: white;
    font-weight: bold;
    font-size: 1.3rem;
}

.header-menu li {
    cursor: pointer;
}

.header-menu li:hover {
    color: var(--orange);
}

.links {
    color: white;
}


.links:hover {
    color: white;
    color: var(--orange);
    transform: scale(1.1);

}

@media screen and (max-width:768px) {
    .header>:nth-child(2) {
        position: fixed;
        right: 2rem;
        z-index: 99;
    }

    .header-menu {
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }

    .links {
        color: white;
    }

}