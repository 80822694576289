.hero {
    display: flex;
    justify-content: space-between;
}

/* left section */

.leftHero {
    padding: 2rem;
    padding-top: 1.5em;
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}


.best-add {
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.best-add span {
    z-index: 2;
}

.best-add div {
    position: absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}

.hero-text {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;

}

.hero-text div:nth-of-type(3) {
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}

.hero-text div:nth-of-type(4) {
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}

.figures {
    display: flex;
    gap: 2rem;
}

.figures div {
    display: flex;
    flex-direction: column;
}

.figures div span:nth-of-type(1) {
    color: white;
    font-size: 2rem;
}

.figures div span:nth-of-type(2) {
    color: var(--gray);
    text-transform: uppercase;
}

.hero-buttons {
    display: flex;
    gap: 1rem;
    font-weight: normal;
}

.hero-buttons :nth-of-type(1) {
    color: white;
    background-color: var(--orange);
    width: 8rem;
}


.hero-buttons :nth-of-type(2) {
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--orange);
}


/* right section */

.rightHero {
    flex: 1;
    position: relative;
    background-color: var(--orange);
}

.rightHero .btn {
    position: absolute;
    right: 3rem;
    top: 2rem;
    color: black;
}

.heart-rate {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: start;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 7rem;
}

.heart-rate img {
    width: 2rem;
}

.heart-rate:nth-child(2) {
    color: var(--gray);
}

.heart-rate span:nth-of-type(2) {
    color: white;
    font-size: 1.5rem;
}


.heroImage {
    position: absolute;
    right: 8rem;
    top: 10rem;
    width: 23rem;
}

.heroImageBack {
    position: absolute;
    right: 20rem;
    top: 4rem;
    width: 23rem;
    z-index: -1;
    width: 15rem;
}

.calories {
    display: flex;
    gap: 2.5rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width: fit-content;
    position: absolute;
    top: 34rem;
    right: 28rem;
}

.calories img {
    width: 5rem;
}

.calories div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calories div span:nth-of-type(1) {
    color: var(--gray);
}

.calories div span:nth-of-type(2) {
    color: white;
    font-size: 1.4rem;
    margin-top: 2px;
}

.heroblur {
    width: 22rem;
    height: 30rem;
    left: 0;

}


@media screen and (max-width:768px) {

    .hero {
        flex-direction: column;
    }

    .heroblur {
        width: 14rem;
    }

    .best-add {
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }

    .hero-text {
        align-items: center;
        justify-content: center;
        font-size: xx-large;
    }

    .hero-text>div:nth-of-type(3) {
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }

    .hero-buttons {
        justify-content: center;
    }

    .figures>div>span:nth-of-type(1) {
        font-size: large;
    }

    .figures>div>span:nth-of-type(2) {
        font-size: small;
    }

    .rightHero{
        position: relative;
        background: none;
    }

    .heart-rate{
        left: 1rem;
        top: 2rem;
    }

    .calories{
        position: relative;
        top: 5rem;
        left: 2rem;
    }

    .calories>img{
        width: 2rem;
    }

    .calories>div>:nth-child(2){
        color: white;
        font-size: 1rem;
    }

    .heroImage{
        position: relative;
        width: 15rem;
        left: 8.8rem;
        top: 4rem;
        align-self: center;
    }

    .heroImageBack{
        width: 15rem;
        left: 2rem;
        top: 0rem;
    }



}