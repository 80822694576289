.reasons {
    margin-top: 6rem;
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
}

.leftReason {
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}

.leftReason img {
    object-fit: cover;
}

.leftReason :nth-child(1) {
    width: 12rem;
    grid-row: 1/3;
    height: 28rem;
}

.leftReason :nth-child(2) {
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}

.leftReason :nth-child(3) {
    width: 14rem;
    grid-column: 2/3;
    grid-row: 2;

}


.leftReason :nth-child(4) {
    width: 10rem;
    grid-column: 3/4;
    grid-row: 2;
    height: 11.2rem;

}

/* Right Section */

.rightReason {
    flex: 1 1;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.rightReason>span {
    font-size: 2rem;
    font-weight: bold;
    color: var(--orange);
}


.rightReason>div {
    color: white;
    font-size: 3rem;
    font-weight: bold;
}

.detailsRight {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.subHeadings {
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}

.subHeadings img {
    width: 2rem;
    height: 2rem;
}

.partners{
    display: flex;
    gap: 1rem;
}

.partners img{
    width: 2.5rem;
}

@media screen and (max-width: 768px) {
    .reasons{
        flex-direction: column;
    }

    .leftReason{
        grid-auto-rows: auto;
        overflow: hidden;
    }

    .leftReason>:nth-child(1){
        width: 7rem;
        height: 17rem;
    }

    
    .leftReason>:nth-child(2){
        width: 15rem;
        height: 10rem;
    }
    
    .leftReason>:nth-child(3){
        width: 7rem;
    }

    
    .leftReason>:nth-child(4){
        width: 7rem;
        height: 5.5rem;
    }


}